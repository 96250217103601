import React from "react";

import {
  useFormik
} from "formik";

import * as yup from "yup";

import {
  makeStyles,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import {
  InfoOutlined
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import DestinationSelection from "../../components/DestinationSelection";
import CustomToolTip from "../../components/CustomToolTip";

import useWhatsapps from "../../hooks/useWhatsApps";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  isDefault: yup
    .boolean(),
  farewellMessage: yup
    .string(),
  destinationType: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  destinationItem: yup
    .number()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  label: yup
    .string()
    .nullable()
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px"
  },

  submitButton: {
    marginTop: "16px"
  }
}));

const ConnectionForm = ({ onSubmit, connection }) => {
  const classes = useStyles();

  const { whatsApps, loading: loadingFetchWhatsapp } = useWhatsapps();

  const destination = connection?.destinations[0];
  const isFirstConnection = !loadingFetchWhatsapp && whatsApps.length === 0;

  const formik = useFormik({
    initialValues: {
      label: connection?.label || "",
      name: connection?.name || "",
      isDefault: connection?.isDefault || false,
      farewellMessage: connection?.farewellMessage || "",
      destinationType: destination?.destinationType || "",
      destinationItem: destination?.destinationTypeId || ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isFirstConnection) {
        values.isDefault = true;
      }

      onSubmit(values);
    }
  });

  const isThereAnotherWhatsappDefault = (
    !loadingFetchWhatsapp &&
    whatsApps.some((whatsapp) => whatsapp.isDefault && whatsapp.id !== connection?.id) &&
    formik.values.isDefault
  );

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="label"
        label={i18n.t("whatsappModal.form.label")}
        name="label"
        variant="outlined"
        value={formik.values.label}
        onChange={formik.handleChange}
        error={formik.touched.label && Boolean(formik.errors.label)}
        helperText={formik.touched.label && formik.errors.label}
      />
      <TextField
        fullWidth
        id="name"
        label={i18n.t("whatsappModal.form.number")}
        name="name"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <CustomToolTip
        content={isThereAnotherWhatsappDefault ?
          i18n.t("formWrapper.form.defaultConnectionTooltipWarn") :
          i18n.t("formWrapper.form.defaultConnectionTooltip")
        }
        disabled={!connection?.isDefault && !isThereAnotherWhatsappDefault}
      >
        <Box 
          display="flex"
          alignItems="center"
        >
            <FormControlLabel
              control={
                <Switch
                  id="isDefault"
                  name="isDefault"
                  color="primary"
                  checked={isFirstConnection || formik.values.isDefault}
                  onChange={formik.handleChange}
                  disabled={connection?.isDefault && !isThereAnotherWhatsappDefault}
                />
              }
              label={i18n.t("whatsappModal.form.default")}
            />
            {
              (connection?.isDefault || isThereAnotherWhatsappDefault) && 
              <InfoOutlined 
                color={connection?.isDefault ? "inherit" : "error"}
              />
            }
        </Box>
      </CustomToolTip>
      <TextField
        fullWidth
        id="farewellMessage" 
        label={i18n.t("whatsappModal.form.farewellMessage")}
        name="farewellMessage"
        multiline
        minRows={4}
        variant="outlined"
        value={formik.values.farewellMessage}
        onChange={formik.handleChange}
        error={formik.touched.farewellMessage && Boolean(formik.errors.farewellMessage)}
        helperText={formik.touched.farewellMessage && formik.errors.farewellMessage}
      />
      <DestinationSelection 
        formik={formik}
      />
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {i18n.t("formWrapper.form.buttons.save")}
      </Button>
    </form>
  );
}

export default ConnectionForm;