const messages = {
  pt: {
    translations: {
      DEFAULT_SUCCESS: "Sucesso",
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Sucesso",
          fail: "Erro",
        },
        form: {
          name: "Nome",
          email: "Email",
          extension: "Ramal",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "E-mail",
          password: "Senha",
          placeholder: {
            email: "Seu e-mail aqui...",
            password: "Sua senha aqui...",
          },
          error: {
            email: "E-mail inválido",
          },
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Sucesso",
        },
      },
      dashboard: {
        filters: {
          user: "Usuário",
          queue: "Fila",
          status: {
            any: "Qualquer",
            label: "Status",
            open: "Em atendimento",
            closed: "Finalizado",
            pending: "Aguardando"
          },
          duration: {
            label: "Duração",
            lastWeek: "Últimos 7 dias",
            lastMonth: "Último mês",
            lastThreeMonths: "Últimos 2 meses",
            custom: "Período personalizado",
            startDate: "De",
            endDate: "Até"
          }
        },
        download: "Baixar relatórios",
        cannotDownload: "Não é possível baixar relatórios com mais de 2 meses de duração!",
        ticketList: {
          title: "Tickets",
          user: {
            label: "Atendente",
            noUser: "Ticket pendente"
          },
          ticket: {
            label: "Identificação do ticket"
          },
          queue: {
            label: "Fila",
            noQueue: "Sem fila"
          },
          whatsapp: {
            label: "Conexão"
          },
          status: {
            label: "Status",
            open: "Em atendimento",
            closed: "Finalizado",
            pending: "Aguardando"
          },
          client: {
            label: "Cliente"
          },
          date: {
            label: "Data"
          },
          results: "resultados"
        },
        reportFileName: "relatorio",
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Sucesso",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: {
            part1: "Você tem certeza? Essa ação não pode ser revertida.",
            part2: "Essa conexão possui",
            part3: "que ainda não foram encerrados.",
            part4:
              "Após a confirmação, todos esses tickets serão automaticamente encerrados.",
          },
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          disconnecting: "Desconectando",
        },
        toolTips: {
          cantAdd: "Número de conexões excedido",
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
          onDisconnect: {
            content: "Essa ação pode levar até dois minutos",
          },
        },
        table: {
          name: "Número do WhatsApp",
          label: "Nome da conexão",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          number: "Número do WhatsApp",
          label: "Nome da conexão",
          default: "Conexão Padrão",
          farewellMessage: "Mensagem de despedida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Sucesso",
          unableWhatsAppId:
            "É necessário que haja uma conexão padrão ou que seu usuário esteja atrelado a uma conexão. Comunique um admin se necessário.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do WhatsApp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      importContactsModal: {
        title: "Importar contatos",
        description: "Importe seus contatos a partir de um arquivo csv",
        template: {
          title: "Modelo do csv",
          select: "Selecionar modelo",
          download: "Baixar modelo",
          types: {
            bradial: "Bradial",
            google: "Google"
          }
        },
        upload: {
          title: "Subir csv",
          chooseFile: "Escolher arquivo",
        },
        successMessage: "Importação iniciada!",
        invalidCSV: "As importações são limitadas em arquivos de 1mb (cerca de 10mil contatos)"
      },
      importContactsProgressModal: {
        title: "Progresso da importação",
        description: "Veja o progresso atual da importação",
        info: {
          successCount: "Contatos importados: ",
          failedCount: "Contatos com falha:",
          repeatedCount: "Contatos duplicados: ",
          totalCount: "Total: "
        },
        successMessage: "Importação finalizada!"
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          extension: "Ramal",
          whatsapp: "Conexão Padrão",
          alert:
            "Esse email está sendo utilizado por um usuário desativado. Deseja reativá-lo utilizando as informações atualizadas do formulário?",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          okEnable: "Ativar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      ticket: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      chat: {
        noTicketMessage: "Selecione um chat para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket foi deletado",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Entrada" },
          closed: { title: "Finalizados" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Conversa",
        fieldUserLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Digite para buscar filas",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketParticipant: {
        confirmationModal: {
          deleteTitle: "Deletar participante",
          leaveTicket: "Sair do ticket",
          leaveMessage:
            "Solicite ao dono do ticket caso queira participar novamente",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        closingHeader: "Finalizados",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhuma conversa encontrada com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Nova Conversa",
        fieldLabel: "Digite para pesquisar o contato",
        groupName: "Nome do grupo",
        add: "Adicionar",
        buttons: {
          ok: "Iniciar",
          cancel: "Cancelar",
        },
      },
      showTicketOwnerModal: {
        title: "Atenção: Ticket já existente",
        owner: "Atendente: ",
        ticket: "Ticket: ",
        status: "Status: ",
        pending: "Aguardando",
        queue: "Fila: ",
        noQueue: "Nenhuma",
        buttons: {
          ok: "Fechar",
        },
      },
      messageList: {
        historyButton: "Ver histórico anterior",
        editedMessage: "Editada",
        forwardedMessage: "Encaminhada",
      },
      selectUsers: {
        title: "Digite para pesquisar o usuário",
      },
      formWrapper: {
        buttons: {
          back: "Voltar",
        },
        titles: {
          new: {
            connection: "Cadastrar Conexão",
            announcement: "Cadastrar Anúncio",
            URA: "Cadastrar URA",
            queue: "Cadastrar Fila",
            user: "Cadastrar Usuário",
            monitoring: "Cadastrar monitoramento",
          },
          edit: "Editar",
        },
        form: {
          label: {
            name: "Nome",
            description: "Descrição",
            selector: "Seletor",
            menuName: "Nome do menu",
            destinationType: "Destino",
            destinationItem: "Valor",
            nullSelection: "Nenhuma conexão",
            selectColor: "Selecione uma cor",
            monitoringStatus: {
              label: "Status",
              open: "Em atendimento",
              closed: "Finalizado",
              owner: "Responsável",
              queue: "Fila",
            },
            monitoringUser: "Usuário",
            monitoringQueue: "Fila",
          },
          buttons: {
            save: "Salvar",
          },
          messages: {
            errors: {
              required: "Esse campo é obrigatório",
              tooLong: "Muito longo!",
            },
          },
          destinationSelection: {
            UserDestination: "Usuário",
            QueueDestination: "Fila",
            AnnouncementDestination: "Anúncio",
            UraDestination: "URA",
            MonitoringDestination: "Monitoramento",
          },
          defaultConnectionTooltip:
            "É obrigatório ter uma conexão padrão. Para alterar a conexão padrão, clique em editar na conexão que deseja atualizar e mude para conexão padrão. Após isso essa conexão automaticamente deixará de ser padrão.",
          defaultConnectionTooltipWarn:
            "Já existe uma conexão definida como padrão. Essa mudança pode gerar impacto no uso do sistema. Tem certeza que deseja alterar?",
        },
      },
      mainDrawer: {
        listItems: {
          title: "Configurações",
          reports: "Relatórios",
          connections: "Conexões",
          conversations: "Conversas",
          tickets: "Tickets",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Filas",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          contactUs: "Fale Conosco",
          about: "Sobre",
          chat: "Chat Interno",
          URA: "URA",
          announcements: "Anúncios",
          monitoring: "Monitoramento",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
        success: "Fila salvo com sucesso.",
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Sucesso",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
          canSeeAllTicketHistory: {
            label: "Ver historico geral",
            tooltip: "Ao ativar a opção, o usuário poderá ver o histórico de todos que já conversaram com o contato do ticket que ele está atendendo"
          },
          extension: "Ramal",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Sucesso",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      about: {
        messages: {
          plain: {
            title: "Plano Mensal",
            caption: "Renovação Automática",
          },
          userInfo: {
            users: "Usuários",
            administrators: "Administradores",
          },
          payment: {
            title: "Forma de Pagamento",
            form: "Boleto Mensal (Pós pago)",
            charge: "Cobrança por e-mail",
          },
          version: {
            title: "Versão",
            lib: "WhatsApp Web lib: ",
            system: "Tag do sistema: ",
          },
          terms: {
            title: "Termos de Uso",
            click: "Clique ",
            here: "aqui ",
            terms: "para ler os nossos termos de uso.",
          },
          config: {
            title: "Configurações de chat",
            close: "Encerrar conversas",
            start: "Após",
            end: "min de inatividade",
          },
        },
      },
      URA: {
        title: "URA",
        table: {
          name: "Nome",
          description: "Descrição",
          actions: "Ações",
        },
        buttons: {
          add: "adicionar URA",
        },
        success: {
          message: "URA cadastrada",
        },
        directDial: "Discagem Direta Ramal",
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Tem certeza que deseja excluir esta URA? Essa ação não pode ser revertida.",
          success: {
            message: "URA excluída com sucesso",
          },
        },
      },
      announcements: {
        title: "Anúncios",
        table: {
          name: "Nome",
          description: "Descrição",
          actions: "Ações",
        },
        buttons: {
          add: "adicionar anúncio",
        },
        confirmationModal: {
          title: "Excluir",
          message:
            "Tem certeza que deseja excluir este anúncio? Essa ação não pode ser revertida.",
          success: {
            message: "Anúncio excluído com sucesso",
          },
        },
      },
      monitoring: {
        title: "Monitoramento",
        buttons: {
          add: "adicionar monitoramento",
        },
        table: {
          name: "Nome",
          status: "Status",
          owner: "Responsável",
          queue: "Fila",
          actions: "Ações",
        },
        confirmationModal: {
          title: "Excluir",
          message:
            "Tem certeza que deseja excluir este monitoramento? Essa ação não pode ser revertida.",
          success: {
            message: "Monitoramento excluído com sucesso",
          },
        },
      },
      contactUs: {
        title: "Fale Conosco",
        email: "Email",
        extension: "Ramal",
        department: "Departamento",
        message: "Mensagem",
        submit: "Enviar",
        subtitle: "Sua opinião é muito importante para nós",
        phone: "(19) 3909-9662",
        contact: "contato@bradial.com.br",
        rua: "Rua Leila Diniz, 395, Jd. Amanda II",
        cep: "Hortolândia, CEP: 13188-165 - SP",
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            addParticipant: "Adicionar participante",
            return: "Retornar as Filas",
            resolve: {
              title: "Finalizar Atendimento",
              actions: {
                withFarewellMessage: "Finalizar com despedida",
                withoutFarewellMessage: "Finalizar sem despedida",
              },
            },
            reopen: "Reabrir",
            accept: "Aceitar",
            addAttendant: "Adicionar participante",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/''",
        placeholderClosed:
          "Reabra ou aceite essa conversa para enviar uma mensagem.",
        signMessage: "Assinar",
        media: {
          sizeExceeded:
            "arquivo que você tentou enviar falhou. Para fotos, vídeos e áudios o limite é 16MB e documentos 100MB.",
          sizeExceededPlural:
            "arquivos que você tentou enviar falharam. Para fotos, vídeos e áudios o limite é 16MB e documentos 100MB.",
        },
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar a conversa do ticket",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        forward: "Encaminhar mensagem",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
        hasntSameReplyingMessageWhatsappId:
          "A mensagem não faz parte da mesma conexão, para respondê-la, abra um ticket a partir da conexão de origem.",
        hasntSameDeletingMessageWhatsappId:
          "A mensagem não faz parte da mesma conexão, para deletá-la, abra um ticket a partir da conexão de origem.",
      },
      messageOptionsFooter: {
        selected: " selecionada",
        selectedMultiple: " selecionadas",
        tooltip: {
          forward: "Encaminhar",
        },
        formardMessageModal: {
          title: "Encaminhar mensagem para",
          search: "Digite para pesquisar",
          cancel: "cancelar",
          actions: {
            confirm: "encaminhar",
          },
          errorFetchListContacts: "Ocorreu um erro ao buscar contatos",
          selectedMaximumReached:
            "Você só pode compartilhar com até 5 conversas",
        },
      },
      feedbackUserModal: {
        bug: {
          title: "Problema",
          label: "Conte com detalhes o que está acontecendo...",
        },
        idea: {
          title: "Ideia",
          label: "Conta pra gente a sua ideia.",
        },
        other: {
          title: "Outro",
          label: "O que você tem para nos dizer?",
        },
        title: "Deixe seu feedback",
        success: "Feedback enviado com sucesso",
        buttons: {
          confirm: "Enviar",
          cancel: "Cancelar",
        },
      },
      managerHeader: {
        searchInput: {
          placeholder: "Digite para pesquisar...",
        },
        showAllTicketsSwitch: {
          activeToolTip: "Todos tickets",
          disabledTooltip: "Seus tickets",
        },
        showAllChatsSwitch: {
          activeToolTip: "Todos chats",
          disabledTooltip: "Seus chats",
        },
        closedTickets: "Finalizados",
      },
      chatList: {
        noChatsTitle: "Nada aqui!",
        noChatsMessage:
          "Nenhuma conversa encontrada com esse status ou termo pesquisado",
      },
      formMessages: {
        input: {
          tooShort: "Muito curto!",
          tooLong: "Muito longo!",
        },
        textArea: {
          tooShort: "Por favor nos dê mais detalhes",
          tooLong: "Por favor seja mais sucinto",
        },
        requiredField: "Campo necessário",
        invalidEmail: "Email inválido",
      },
      detailsDrawer: {
        title: "Detalhes",
        members: "Membros",
      },
      addChatParticipant: {
        title: "Adicionar outros participantes",
        buttons: {
          ok: "Incluir",
          cancel: "Cancelar",
        },
      },
      VcardPreview: {
        errorMessages: {
          noNumberContact: { tooltip: "Não há um número nesse contato." },
          noInfoContact: {
            tooltip:
              "⚠️ Não foi possível obter as informações desse contato. Verifique a mensagem no aplicativo em seu telefone. ⚠️",
            name: "Contato inválido",
          },
        },
        talk: "Conversar",
      },
      noQueue: "sem fila",
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Verifique a página de conexões",
        ERR_NO_DEF_WAPP_FOUND: "Verifique a página de conexões",
        ERR_WAPP_NOT_INITIALIZED: "Verifique a página de conexões",
        ERR_WAPP_CHECK_CONTACT: "Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Número inválido",
        ERR_WAPP_DOWNLOAD_MEDIA: "Verifique a página de conexões",
        ERR_INVALID_CREDENTIALS: "Acesso negado",
        ERR_SENDING_WAPP_MSG: "Verifique a página de conexões",
        ERR_DELETE_WAPP_MSG: "Erro",
        ERR_OTHER_OPEN_TICKET: "Já existe um ticket com esse contato",
        ERR_SESSION_EXPIRED: "Sessão expirada",
        ERR_USER_CREATION_DISABLED: "Erro",
        ERR_NO_PERMISSION: "Acesso negado",
        ERR_DUPLICATED_CONTACT: "Contato já existe",
        ERR_NO_SETTING_FOUND: "Erro",
        ERR_NO_CONTACT_FOUND: "Erro",
        ERR_NO_TICKET_FOUND: "Erro",
        ERR_NO_USER_FOUND: "Usuário não encontrado",
        ERR_NO_QUEUE_FOUND: "Fila não encontrada",
        ERR_NO_MONITORING_FOUND: "Monitoramento não encontrado",
        ERR_NO_WAPP_FOUND: "Erro",
        ERR_CREATING_MESSAGE: "Erro",
        ERR_CREATING_TICKET: "Erro",
        ERR_FETCH_WAPP_MSG: "Erro",
        ERR_QUEUE_COLOR_ALREADY_EXISTS: "Cor já em uso",
        ERR_WAPP_GREETING_REQUIRED: "Mensagem de saudação é obrigatório",
        ERR_MISSING_VALUES: "Informações incompletas",
        ERR_SEND_MAIL: "Erro",
        ERR_NO_CHAT_FOUND: "Chat não encontrado com esse ID",
        ERR_CREATING_CHAT: "Erro ao criar chat",
        ERR_OTHER_OPEN_CHAT: "Já existe um chat com esses participantes",
        ERR_NO_URA_FOUND: "Ura não encontrado com esse ID",
        ERR_NO_URAITEM_FOUND: "Item Ura não encontrado com esse ID",
        ERR_NO_DESTINATION_FOUND: "Destino não encontrado com esse ID",
        ERR_DUPLICATED_URAITEM_SELECTOR: "Item da Ura com seletor duplicado",
        ERR_INBOUND_CONFIGURATION: "Conexão sem Inbound route configurado!",
        ERR_QUEUE_NOT_FOUND: "Fila no encontrada!",
        ERR_ANNOUNCEMENT_NAME_EXISTS: "O nome do anúncio já existe",
        ERR_NO_ANNOUNCEMENT_FOUND: "Anúncio não encontrado com este ID",
        ERR_DISCONNECT_WPP: "Erro ao desconectar",
        ERR_MONITORING_NAME_EXISTS: "O nome do monitoramento já existe",
        ERR_NO_DATE_SPECIFIED: "Nenhum filtro de data especificado!",
        FILE_NOT_SUPPORTED: "Arquivo não suportado!",
        WPP_NOT_CONNECTED: "WhatsApp está desconectado!",
        ERR_INVALID_CSV_TEMPLATE: "Modelo de CSV inválido!",
        ERR_EMPTY_CSV: "CSV vazio!",
        ERR_INVALID_BRADIAL_CSV: "CSV não atende o modelo Bradial!",
        ERR_INVALID_GOOGLE_CSV: "CSV não atende o modelo Google!"

      },
    },
  },
};

export { messages };
