const messages = {
  es: {
    translations: {
      DEFAULT_SUCCESS: "Éxito",
      signup: {
        title: "Registro",
        toasts: {
          success:
            "¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
          fail: "Error creando el usuario. Verifica la data reportada.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          extension: "Extensión",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "Inicio de Sesión",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
          placeholder: {
            email: '"Correo Electrónico aqui...',
            password: "Contraseña aqui...",
          },
          error: {
            email: "Correo Electrónico inválido",
          },
        },
        buttons: {
          submit: "Ingresa",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
      },
      dashboard: {
        filters: {
          user: "Usuario",
          queue: "Cola",
          status: {
            any: "Cualquier",
            label: "Estado",
            open: "En curso",
            closed: "Finalizado",
            pending: "Pendiente",
          },
          duration: {
            label: "Duração",
            lastWeek: "Los últimos 7 días",
            lastMonth: "El mes pasado",
            lastThreeMonths: "Últimos 2 meses",
            custom: "Periodo personalizado",
            startDate: "Desde",
            endDate: "Hasta"
          }
        },
        download: "Descargar reportes",
        cannotDownload: "¡No es posible descargar informes que tengan más de 2 meses!",
        ticketList: {
          title: "Tickets",
          user: {
            label: "Asistente",
            noUser: "Ticket pendiente"
          },
          ticket: {
            label: "Identificación del  ticket"
          },
          queue: {
            label: "Cola",
            noQueue: "Sin cola"
          },
          whatsapp: {
            label: "Conexión"
          },
          status: {
            label: "Estado",
            open: "En curso",
            closed: "Finalizado",
            pending: "Pendiente",
          },
          client: {
            label: "Cliente"
          },
          date: {
            label: "Fecha"
          },
          results: "resultados"
        },
        reportFileName: "informe",
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted:
            "¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: {
            part1: "¿Estás seguro? Este proceso no puede ser revertido.",
            part2: "Esta conexión tiene",
            part3: "que aún no han sido cerrados.",
            part4:
              "Tras la confirmación, todas estas entradas se cerrarán automáticamente.",
          },
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega WhatsApp",
          disconnect: "Desconectar",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
          disconnecting: "Desconectando",
        },
        toolTips: {
          cantAdd: "Número de conexiones excedido",
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
          onDisconnect: {
            content: "Esta acción puede tardar hasta dos minutos",
          },
        },
        table: {
          name: "Número de WhatsApp",
          label: "Nombre de la conexión",
          status: "Estado",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agrega WhatsApp",
          edit: "Edita WhatsApp",
        },
        form: {
          number: "Número de WhatsApp",
          label: "Nombre de la conexión",
          default: "Conexión predeterminada",
          farewellMessage: "Mensaje de despedida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado satisfactoriamente.",
      },
      qrCode: {
        message: "Lée el código QR para empezar la sesión.",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto borrado satisfactoriamente!",
          unableWhatsAppId:
            "Debe haber una conexión predeterminada o su usuario está conectado a una conexión. Informe a un administrador si es necesario.",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Borrar",
          importTitlte: "Importar contactos",
          deleteMessage:
            "¿Estás seguro que deseas borrar este contacto? Todos los tickets relacionados se perderán.",
          importMessage:
            "¿Quieres importar todos los contactos desde tu teléfono?",
        },
        buttons: {
          import: "Importar Contactos",
          add: "Agregar Contacto",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          actions: "Acciones",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          email: "Correo Electrónico",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado satisfactoriamente.",
      },
      importContactsModal: {
        title: "Importar contactos",
        description: "Importar contactos desde un archivo csv",
        template: {
          title: "plantilla CSV",
          select: "Seleccionar plantilla",
          download: "Descargar plantilla",
          types: {
            bradial: "Bradial",
            google: "Google"
          }
        },
        upload: {
          title: "Subir CSV",
          chooseFile: "Elija el archivo",
        },
        successMessage: "¡Comenzó la importación!",
        invalidCSV: "Las importaciones están limitadas a archivos de 1 MB (alrededor de 10.000 contactos)"
      },
      importContactsProgressModal: {
        title: "Progreso de importación",
        description: "Ver el progreso de importación actual",
        info: {
          successCount: "Contactos importados: ",
          failedCount: "Contactos fallidos:",
          repeatedCount: "Contactos duplicados: ",
          totalCount: "Total: "
        },
        successMessage: "¡Importación completa!"
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada correctamente.",
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          profile: "Perfil",
          extension: "Extensión",
          whatsapp: "Conexión estándar",
          alert:
            "Este correo electrónico está siendo utilizado por un usuario desactivado. Quieres reactivarlo usando la información actualizada del formulario?",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          okEnable: "Activar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
      ticket: {
        noTicketMessage: "Selecciona un ticket para empezar a chatear.",
      },
      chat: {
        noTicketMessage: "Selecciona un chat para empezar a chatear.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Linhas",
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido borrado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar tickets y mensajes.",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldUserLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Escriba para buscar colas",
        fieldConnectionLabel: "Transferir to conexión",
        fieldQueuePlaceholder: "Seleccione una cola",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketParticipant: {
        confirmationModal: {
          deleteTitle: "Eliminar participante",
          leaveTicket: "Dejar el chat",
          leaveMessage:
            "Pregúntale al dueño del ticket si quieres volver a participar",
        },
      },
      ticketsList: {
        pendingHeader: "Cola",
        assignedHeader: "Trabajando en",
        noTicketsTitle: "¡Nada acá!",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        noTicketsMessage:
          "No se encontraron tickets con este estado o término de búsqueda",
        buttons: {
          accept: "Acceptar",
        },
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar un contacto",
        groupName: "Group name",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      showTicketOwnerModal: {
        title: "Atención: Ticket ya existe",
        owner: "Asistente: ",
        ticket: "Ticket: ",
        status: "Status: ",
        pending: "Esperando",
        queue: "Cola: ",
        noQueue: "Ninguna",
        buttons: {
          ok: "Cerrar",
        },
      },
      messageList: {
        historyButton: "Ver historial anterior",
        editedMessage: "Editado",
        forwardedMessage: "Reenviado",
      },
      selectUsers: {
        title: "Escriba para buscar usuario",
      },
      formWrapper: {
        buttons: {
          back: "Volver",
        },
        titles: {
          new: {
            connection: "Registrar Conexión",
            announcement: "Registrar Anuncio",
            URA: "Registrar URA",
            queue: "Registrar Cola",
            user: "Registrar Usuario",
            monitoring: "Registrar Supervisión",
          },
          edit: "Editar",
        },
        form: {
          label: {
            name: "Nombre",
            description: "Descripción",
            destinationType: "Destino",
            destinationItem: "Valor",
            nullSelection: "Sin conexión",
            selectColor: "Seleccione un color",
            monitoringStatus: {
              label: "Estado",
              open: "En servicio",
              closed: "Finalizado",
            },
            monitoringUser: "Usuario",
            monitoringQueue: "Linha",
            owner: "Responsable",
            queue: "Queue",
          },
          buttons: {
            save: "Guardar",
          },
          messages: {
            errors: {
              required: "Este campo es obligatorio",
              tooLong: "por favor se mas conciso",
            },
          },
          destinationSelection: {
            UserDestination: "Usuario",
            QueueDestination: "Cola",
            AnnouncementDestination: "Anuncio",
            UraDestination: "URA",
            MonitoringDestination: "Supervisión",
          },
          defaultConnectionTooltip:
            "Es obligatorio tener una conexión estándar. Para cambiar la conexión predeterminada, haga clic en editar en la conexión que desea actualizar y cambie a la conexión predeterminada. Después de eso, esta conexión dejará automáticamente de ser la predeterminada.",
          defaultConnectionTooltipWarn:
            "Ya hay una conexión configurada por defecto. Este cambio puede afectar el uso del sistema. ¿Estás seguro de que quieres cambiar?",
        },
      },
      mainDrawer: {
        listItems: {
          title: "Ajustes",
          reports: "Informes",
          connections: "Conexiones",
          conversations: "Conversaciones",
          tickets: "Tickets",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          queues: "Linhas",
          administration: "Administración",
          users: "Usuarios",
          settings: "Configuración",
          contactUs: "Hable con nosotros",
          about: "Sobre",
          chat: "Chat Interno",
          URA: "URA",
          announcements: "Anuncios",
          monitoring: "Supervisión",
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      queues: {
        title: "Linhas",
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Comportamiento",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
        },
        success: "Cola guardado exitosamente.",
      },
      queueSelect: {
        inputLabel: "Linhas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada correctamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          actions: "Acciones",
          canSeeAllTicketHistory: {
            label: "Ver toda la historia",
            tooltip: "Al activar esta opción, el usuario podrá ver el historial completo de contactos del ticket actual"
          },
          extension: "Extensión",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario borrado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Los tickets abiertos de los usuarios se moverán a la cola.",
        },
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        settings: {
          userCreation: {
            name: "Creación de usuarios",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
        },
      },
      about: {
        messages: {
          plain: {
            title: "Plan mensual",
            caption: "Renovación automática",
          },
          userInfo: {
            users: "Usuarios",
            administrators: "Administradores",
          },
          payment: {
            title: "Forma de pago",
            form: "Factura mensual (Post pagado)",
            charge: "Cobrar por correo electrónico",
          },
          version: {
            title: "Versión",
            lib: "WhatsApp Web lib: ",
            system: "Etiqueta del sistema: ",
          },
          terms: {
            title: "Términos de Uso",
            click: "Haga clic ",
            here: "aquí ",
            terms: "para leer nuestros términos de uso.",
          },
          config: {
            title: "Configuraciones de chat",
            close: "Cerrar chats",
            start: "después",
            end: "minutos de inactividad",
          },
        },
        URA: {
          title: "URA",
          table: {
            name: "Nombre",
            description: "Descripción",
            actions: "Acciones",
          },
          buttons: {
            add: "Agregar URA",
          },
          success: {
            message: "URA Registrado",
          },
          directDial: "Extensión de Marcación Directa",
          confirmationModal: {
            deleteTitle: "Borrar",
            deleteMessage:
              "¿Está seguro de que desea eliminar esta URA? Esta acción no se puede revertir.",
            success: {
              message: "URA eliminado con éxito",
            },
          },
        },
      },
      announcements: {
        title: "Anuncios",
        table: {
          name: "Nombre",
          description: "Descripción",
          selector: "Seletor",
          menuName: "Nombre del menú",
          actions: "Acciones",
        },
        buttons: {
          add: "agregar anuncio",
        },
        confirmationModal: {
          title: "Borrar",
          message:
            "¿Estás seguro de que quieres eliminar este anuncio? Esta acción no se puede revertir.",
          success: {
            message: "Anuncio eliminado con éxito",
          },
        },
      },
      monitoring: {
        title: "Supervisión",
        buttons: {
          add: "Agregar supervisión",
        },
        table: {
          name: "Nombre",
          status: "Estato",
          owner: "Responsable",
          queue: "Linha",
          actions: "Acciones",
        },
        confirmationModal: {
          title: "Excluir",
          message:
            "Tem certeza que deseja excluir este monitoramento? Essa ação não pode ser revertida.",
          success: {
            message: "Monitoramento excluído com sucesso",
          },
        },
      },
      contactUs: {
        title: "Hable con nosotros",
        email: "Correo electrónico",
        extension: "Extensión",
        department: "Departamento",
        message: "Mensaje",
        submit: "Enviar",
        subtitle: "Su opinión es muy importante para nosotros",
        phone: "(19) 3909-9662",
        contact: "contato@bradial.com.br",
        rua: "Rua Leila Diniz, 395, Jd. Amanda II",
        cep: "Hortolândia, CEP: 13188-165 - SP",
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            addParticipant: "Añada participante",
            return: "Devolver",
            resolve: {
              title: "Resolver",
              actions: {
                withFarewellMessage: "Resolver con despedida",
                withoutFarewellMessage: "Resolver sin despedida",
              },
            },
            reopen: "Reabrir",
            accept: "Aceptar",
            addAttendant: "Añada participante",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Escriba un mensaje o presione '' / '' para usar las respuestas rápidas registradas",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
        media: {
          sizeExceeded:
            "archivo que intentó cargar falló. Para fotos, videos y audios el límite es de 16MB y documentos de 100MB.",
          sizeExceededPlural:
            "archivos que intentó cargar fallaron. Para fotos, videos y audios el límite es de 16MB y documentos de 100MB.",
        },
      },
      contactDrawer: {
        header: "Detalles del contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otra información",
      },
      ticketOptionsMenu: {
        delete: "Borrar",
        transfer: "Transferir",
        confirmationModal: {
          title: "¿Borrar ticket?",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Borrar",
        reply: "Responder",
        forward: "Reenviar mensaje",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
        hasntSameReplyingMessageWhatsappId:
          "El mensaje al que se hace referencia no forma parte de esta conexión de ticket, para responderlo, abra un ticket desde la conexión de origen.",
        hasntSameDeletingMessageWhatsappId:
          "El mensaje al que se hace referencia no forma parte de esta conexión de ticket, para bórralo, abra un ticket desde la conexión de origen.",
      },
      messageOptionsFooter: {
        selected: " seleccionada",
        selectedMultiple: " seleccionadas",
        tooltip: {
          forward: "Reenviar",
        },
        formardMessageModal: {
          title: "Reenviar mensaje a",
          search: "Escribe para buscar",
          cancel: "cancelar",
          actions: {
            confirm: "reenviar",
          },
          errorFetchListContacts: "Ocurrió un error al obtener contactos",
          selectedMaximumReached:
            "Solo puedes compartir con hasta 5 conversaciones",
        },
      },
      feedbackUserModal: {
        bug: {
          title: "Problema",
          label: "Cuéntanos en detalle lo que está pasando...",
        },
        idea: {
          title: "Ocurrencia",
          label: "Cuéntanos tu idea.",
        },
        other: {
          title: "Otros",
          label: "¿Qué tienes que decir de nosotros?",
        },
        title: "Deja tus comentarios",
        success: "Comentarios enviados con éxito",
        buttons: {
          confirm: "Confirmar",
          cancel: "Cancelar",
        },
      },
      managerHeader: {
        searchInput: {
          placeholder: "Escribe para buscar...",
        },
        showAllTicketsSwitch: {
          activeToolTip: "Todos los tickets",
          disabledTooltip: "Tus tickets",
        },
        showAllChatsSwitch: {
          activeToolTip: "Todos los chats",
          disabledTooltip: "Tus chats",
        },
        closedTickets: "Finalizado",
      },
      chatList: {
        noChatsTitle: "Nada aquí!",
        noChatsMessage:
          "No se encontraron chats con este estado o término buscado.",
      },
      formMessages: {
        input: {
          tooShort: "¡Muy corto!",
          tooLong: "¡Muy largo!",
        },
        textArea: {
          tooShort: "Por favor danos más detalles",
          tooLong: "por favor se mas conciso",
        },
        requiredField: "Campo requerido",
        invalidEmail: "Email inválido",
      },
      detailsDrawer: {
        title: "Detalles",
        members: "Miembros",
      },
      addChatParticipant: {
        title: "Añadir otros participantes",
        buttons: {
          ok: "Incluir",
          cancel: "Cancelar",
        },
      },
      VcardPreview: {
        errorMessages: {
          noNumberContact: { tooltip: "No hay números en este contacto enviado" },
          noInfoContact: {
            tooltip: "⚠️ No pudimos obtener esta información de contacto. Por favor, revisa el mensaje en la aplicación de tu teléfono. ⚠️",
            name: "Contacto no válido"
          },
        },
        talk: "Hablar",
      },
      noQueue: "sin cola",
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED:
          "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND:
          "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_QUEUE_FOUND: "Cola no encontrada",
        ERR_NO_MONITORING_FOUND: "Supervisión no encontrado",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de una cola.",
        ERR_MISSING_VALUES: "Información incompleta",
        ERR_SEND_MAIL: "Error",
        ERR_NO_CHAT_FOUND: "No se encontró ningún chat con este ID.",
        ERR_CREATING_CHAT: "Error al crear el chat",
        ERR_OTHER_OPEN_CHAT: "Ya hay un chat abierto para estes participantes.",
        ERR_NO_URA_FOUND: "No se encontró ningúna ura con este ID.",
        ERR_NO_URAITEM_FOUND: "No se encontró ningún item ura con este ID.",
        ERR_NO_DESTINATION_FOUND: "No se encontró ningún destino con este ID.",
        ERR_DUPLICATED_URAITEM_SELECTOR:
          "Valor seleccionado duplicado para el elemento Ura",
        ERR_INBOUND_CONFIGURATION: "Conexión sin Ruta de entrada!",
        ERR_QUEUE_NOT_FOUND: "Fila no encontrada!",
        ERR_ANNOUNCEMENT_NAME_EXISTS: "El nombre del anuncio ya existe",
        ERR_NO_ANNOUNCEMENT_FOUND: "No se encontró anuncio con este ID",
        ERR_DISCONNECT_WPP: "Error al desconectar",
        ERR_NO_DATE_SPECIFIED: "No se ha especificado ningún filtro de fecha",
        FILE_NOT_SUPPORTED: "Archivo no soportado!",
        WPP_NOT_CONNECTED: "WhatsApp está desconectado!",
        ERR_INVALID_CSV_TEMPLATE: "Plantilla CSV no válida!",
        ERR_EMPTY_CSV: "CSV vacío!",
        ERR_INVALID_BRADIAL_CSV: "¡CSV no es compatible con el modelo Bradial!",
        ERR_INVALID_GOOGLE_CSV: "¡CSV no cumple con el modelo de Google!"
      },
    },
  },
};

export { messages };
